import { useIntl } from "react-intl";
import { questionById, questionHasChildren, QuestionType, } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { splitDateString } from "@lookiero/sty-psp-quiz-style-profile-common-ui/dist/src/infrastructure/ui/components/organisms/question/behaviours/dateUtils";
import { splitImperialHeightString, splitImperialWeightString, } from "@lookiero/sty-psp-quiz-style-profile-common-ui/dist/src/infrastructure/ui/components/organisms/question/behaviours/mapAnswersToImperialSystem";
const sortAnswersByDefinition = ({ question, answers }) => {
    const sortedAnswers = {};
    const mapQuestion = (question) => {
        const questionAnswer = answers[question.id];
        if (questionAnswer) {
            sortedAnswers[question.id] = questionAnswer;
        }
        if (questionHasChildren(question)) {
            question.children.forEach(mapQuestion);
        }
    };
    mapQuestion(question);
    return sortedAnswers;
};
const EMPTY_ANSWER = "-";
const UUID_REGEX = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;
const isUuid = (value) => typeof value === "string" && UUID_REGEX.test(value);
const useQuestionAnswers = ({ question, answers }) => {
    const { formatMessage } = useIntl();
    const sortedAnswers = sortAnswersByDefinition({ question, answers });
    const allQuestionIds = Object.keys(sortedAnswers);
    // Uploaded photos
    if (allQuestionIds[0] &&
        questionById({ questions: [question], questionId: allQuestionIds[0] })?.type === QuestionType.HOST_IMAGE_UPLOAD) {
        return String(allQuestionIds.length);
    }
    const allAnswers = Object.entries(sortedAnswers)
        .reduce((acc, [questionId, questionAnswers]) => {
        const questionType = questionById({ questions: [question], questionId })?.type;
        if (!questionType) {
            return [...acc, EMPTY_ANSWER];
        }
        switch (questionType) {
            case QuestionType.HOST_DATE:
                const { year, month, day } = splitDateString({ date: questionAnswers[0] });
                return [...acc, `${day}/${month}/${year}`];
            case QuestionType.HOST_HEIGHT_IMPERIAL:
                const { feet, inches } = splitImperialHeightString({ height: questionAnswers[0] });
                return [...acc, `${feet} ft ${inches} in`];
            case QuestionType.HOST_HEIGHT_METRIC:
                return [...acc, `${questionAnswers[0]} cm`];
            case QuestionType.HOST_WEIGHT_IMPERIAL:
                const { pounds, stones } = splitImperialWeightString({ weight: questionAnswers[0] });
                return [...acc, `${stones} st ${pounds} lb`];
            case QuestionType.HOST_WEIGHT_METRIC:
                return [...acc, `${questionAnswers[0]} kg`];
            default:
                return [
                    ...acc,
                    ...questionAnswers.reduce((answersAcc, answer) => {
                        if (!isUuid(answer)) {
                            return [...answersAcc, answer];
                        }
                        const answerTranslationKey = questionById({ questions: [question], questionId: answer })?.translationKey;
                        return answerTranslationKey ? [...answersAcc, formatMessage({ id: answerTranslationKey })] : answersAcc;
                    }, []),
                ];
        }
    }, [])
        .join(", ");
    return allAnswers.length > 0 ? allAnswers : EMPTY_ANSWER;
};
export { useQuestionAnswers, EMPTY_ANSWER };
