import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space4, colorTextMedium } = theme();
const ICON_SIDE = 60;
const style = StyleSheet.create({
    container: {
        alignItems: "center",
    },
    ctaContainer: {
        paddingTop: space4,
        width: "100%",
    },
    description: {
        color: colorTextMedium,
        textAlign: "center",
    },
    icon: {
        height: ICON_SIDE,
        width: ICON_SIDE,
    },
    textContainer: {
        paddingVertical: space4,
        width: "100%",
    },
    title: {
        textAlign: "center",
    },
});
export { style };
