import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { colorBgPrimaryLight, colorTextMedium, space2, space6, space8 } = theme();
const style = StyleSheet.create({
    bodyGap: {
        marginTop: space6,
    },
    bodyRow: {
        paddingHorizontal: space6,
    },
    content: {
        paddingTop: space8,
    },
    description: {
        color: colorTextMedium,
    },
    divider: {
        backgroundColor: colorBgPrimaryLight,
        height: space2,
    },
    lookAndLike: {
        paddingVertical: space8,
    },
    title: {
        marginBottom: 0,
    },
});
export { style };
