import React, { createContext, useContext } from "react";
import invariant from "tiny-invariant";
const StaticInfoContext = createContext(null);
const StaticInfoProvider = ({ children, apiUrl, getAuthToken, basePath, customer, kameleoon, }) => (React.createElement(StaticInfoContext.Provider, { value: { apiUrl, getAuthToken, basePath, customer, kameleoon } }, children));
const useStaticInfo = () => {
    const staticInfo = useContext(StaticInfoContext);
    invariant(staticInfo, "Your are trying to use the useStaticInfo hook without wrapping your app with the <StaticInfoProvider>.");
    return staticInfo;
};
export { useStaticInfo, StaticInfoProvider };
