import React from "react";
import { Svg, Path } from "react-native-svg";
import { theme } from "@lookiero/sty-psp-ui";
const { colorIcon } = theme();
const Measurements = () => (React.createElement(Svg, { fill: "none", viewBox: "0 0 40 40" },
    React.createElement(Path, { d: "M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 14.4533 20.0198 16.6756 18.4273 18.3C16.7927 19.9674 14.5179 21 12 21Z", stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Path, { d: "M12 15.1667C10.2511 15.1667 8.83333 13.7489 8.83333 12C8.83333 10.2511 10.2511 8.83333 12 8.83333C13.7489 8.83333 15.1667 10.2511 15.1667 12C15.1667 12.8633 14.8223 13.6446 14.2615 14.2166C13.6858 14.8039 12.886 15.1667 12 15.1667Z", stroke: colorIcon, strokeWidth: "1.66667" }),
    React.createElement(Path, { d: "M21 12V21.1667", stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Path, { d: "M28 37L28 29", stroke: colorIcon, strokeWidth: "1.66667" }),
    React.createElement(Path, { d: "M20 37L20 29", stroke: colorIcon, strokeWidth: "1.66667" }),
    React.createElement(Path, { d: "M12 37L12 29", stroke: colorIcon, strokeWidth: "1.66667" }),
    React.createElement(Path, { d: "M3 12L3 29C3 33.4183 6.58172 37 11 37H37V21H12", stroke: colorIcon, strokeWidth: "2" })));
export { Measurements };
