import { useCallback } from "react";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName } from "./tracking";
const useTrackUploadPhotoChooseLibraryOptionCanceled = ({ country, segment, customerId, }) => {
    const emitUserEvent = useEmitUserEvent();
    const trackUploadPhotoChooseLibraryOptionCanceled = useCallback(() => {
        emitUserEvent({
            event: TrackingEventName.UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_CANCELED,
            eventCategory: TrackingEventCategory.NAVIGATION,
            section: `${PROJECT}_photos`,
            store: country,
            segment,
            userId: customerId,
        });
    }, [country, customerId, emitUserEvent, segment]);
    return trackUploadPhotoChooseLibraryOptionCanceled;
};
export { useTrackUploadPhotoChooseLibraryOptionCanceled };
