const httpScreenBySlugAndCustomerIdView = ({ httpPost }) => async ({ slug, customerId, experiment, signal }) => await httpPost({
    endpoint: "/view-style-profile-screen-by-slug-and-customer-id",
    body: { slug, customerId, experiment },
    signal,
    result: {
        error: null,
        success: (response) => response,
    },
});
export { httpScreenBySlugAndCustomerIdView };
