import { useCallback } from "react";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName } from "./tracking";
const useTrackUploadPhotoTakePhotoOptionSelected = ({ country, segment, customerId, }) => {
    const emitUserEvent = useEmitUserEvent();
    const trackUploadPhotoTakePhotoOptionSelected = useCallback(() => {
        emitUserEvent({
            event: TrackingEventName.UPLOAD_PHOTO_TAKE_PHOTO_OPTION_SELECTED,
            eventCategory: TrackingEventCategory.NAVIGATION,
            section: `${PROJECT}_photos`,
            store: country,
            segment,
            userId: customerId,
        });
    }, [country, emitUserEvent, customerId, segment]);
    return trackUploadPhotoTakePhotoOptionSelected;
};
export { useTrackUploadPhotoTakePhotoOptionSelected };
