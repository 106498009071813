import { useCallback } from "react";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName } from "./tracking";
const useTrackFillScreen = ({ slug, country, segment, customerId }) => {
    const emitUserEvent = useEmitUserEvent();
    const trackFillScreen = useCallback(({ group, question, answers }) => {
        emitUserEvent({
            event: TrackingEventName.FILL_STYLE_PROFILE,
            eventCategory: TrackingEventCategory.NAVIGATION,
            section: `${PROJECT}_${slug}`,
            store: country,
            segment,
            group,
            question,
            answers,
            userId: customerId,
        });
    }, [country, customerId, emitUserEvent, segment, slug]);
    return trackFillScreen;
};
export { useTrackFillScreen };
