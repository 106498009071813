const httpStyleProfileNextBestActionDefinitionByCustomerIdView = ({ httpPost }) => async ({ customerId, signal }) => await httpPost({
    endpoint: "/view-style-profile-next-best-action-definition-by-customer-id",
    body: { customerId },
    signal,
    result: {
        error: null,
        success: (response) => response,
    },
});
export { httpStyleProfileNextBestActionDefinitionByCustomerIdView };
