import { Platform } from "react-native";
import { fetchHttpPost, multipartFetchHttpPost } from "@lookiero/sty-psp-http";
import { VERSION } from "../../version";
import { getImage, saveImage } from "../domain/image/model/httpImages";
import { getScreen, saveScreen } from "../domain/screen/model/httpScreens";
import { httpImageByIdView } from "../projection/image/httpImageByIdView";
import { httpStyleProfileNextBestActionDefinitionByCustomerIdView } from "../projection/nextBestActionDefinition/httpStyleProfileNextBestActionDefinitionByCustomerIdView";
import { httpScreenBySlugAndCustomerIdView } from "../projection/screen/httpScreenBySlugAndCustomerIdView";
import { httpStyleProfileAnswersByCustomerIdView } from "../projection/styleProfileAnswers/httpStyleProfileAnswersByCustomerIdView";
import { httpStyleProfileDefinitionByCustomerIdView } from "../projection/styleProfileDefinition/httpStyleProfileDefinitionByCustomerIdView";
import { baseBootstrap } from "./baseBootstrap";
const device = Platform.OS;
const bootstrap = ({ apiUrl, getAuthToken }) => {
    const httpPost = fetchHttpPost({ apiUrl, getAuthToken, device, version: VERSION });
    const multipartHttpPost = multipartFetchHttpPost({ apiUrl, getAuthToken, device, version: VERSION });
    return baseBootstrap({
        styleProfileDefinitionByCustomerIdView: httpStyleProfileDefinitionByCustomerIdView({ httpPost }),
        screenBySlugAndCustomerIdView: httpScreenBySlugAndCustomerIdView({ httpPost }),
        styleProfileAnswersByCustomerIdView: httpStyleProfileAnswersByCustomerIdView({ httpPost }),
        styleProfileNextBestActionDefinitionByCustomerIdView: httpStyleProfileNextBestActionDefinitionByCustomerIdView({
            httpPost,
        }),
        getScreen,
        saveScreen,
        screensDependencies: [{ httpPost }],
        imageByIdView: httpImageByIdView({ httpPost }),
        getImage,
        saveImage,
        imagesDependencies: [{ multipartHttpPost }],
    });
};
export { bootstrap };
