import React, { useCallback } from "react";
import { Image } from "react-native";
import { useLocation, useNavigate } from "react-router-native";
import { Button, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Body } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { AspectRatioView } from "@lookiero/sty-psp-ui";
import { TrackingPage } from "../../../tracking/tracking";
import { useTrackPageView } from "../../../tracking/useTrackPageView";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { Routes } from "../../routing/routes";
import { style } from "./NotFound.style";
const NOT_FOUND_DESCRIPTION = "not_found.description";
const NOT_FOUND_BUTTON = "not_found.button";
const NotFound = ({ layout: Layout }) => {
    const descriptionText = useI18nMessage({ id: NOT_FOUND_DESCRIPTION });
    const buttonText = useI18nMessage({ id: NOT_FOUND_BUTTON });
    const { basePath, customer: { customerId, country, segment }, } = useStaticInfo();
    useTrackPageView({
        country,
        segment,
        page: TrackingPage.NOT_FOUND,
        customerId,
    });
    const { state } = useLocation();
    const navigate = useNavigate();
    const handleOnPress = useCallback(() => navigate(`${basePath}/${Routes.HOME}`, { state }), [basePath, navigate, state]);
    return (React.createElement(Layout, { style: { scrollView: style.container } },
        React.createElement(Body, { style: { column: style.bodyColumn } },
            React.createElement(AspectRatioView, { aspectRatio: 1.25 },
                React.createElement(Image, { resizeMode: "contain", source: require("../../../../../public/images/not-found.png"), style: style.image, testID: "not-found-image" })),
            React.createElement(Text, { level: 1, style: style.description }, descriptionText),
            React.createElement(Button, { onPress: handleOnPress }, buttonText))));
};
export { NotFound };
