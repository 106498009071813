var I18nMessages;
(function (I18nMessages) {
    I18nMessages["YOU_PHOTOS_SUBTITLE"] = "you.photos.subtitle";
    I18nMessages["IDEAL_STYLES_SUBTITLE"] = "ideal.styles.subtitle";
    I18nMessages["SILHOUETTE_SILHOUETTE_SUBTITLE"] = "silhouette.silhouette.subtitle";
    I18nMessages["SILHOUETTE_SILHOUETTE_GUIDE"] = "silhouette.silhouette.guide";
    I18nMessages["SILHOUETTE_SILHOUETTE_GUIDE_LINK"] = "silhouette.silhouette.guide_link";
    I18nMessages["SILHOUETTE_SILHOUETTE_TRIANGLE_TITLE"] = "silhouette.silhouette.triangle";
    I18nMessages["SILHOUETTE_SILHOUETTE_TRIANGLE_TEXT"] = "silhouette.silhouette.triangle.text";
    I18nMessages["SILHOUETTE_SILHOUETTE_OVAL_TITLE"] = "silhouette.silhouette.oval";
    I18nMessages["SILHOUETTE_SILHOUETTE_OVAL_TEXT"] = "silhouette.silhouette.oval.text";
    I18nMessages["SILHOUETTE_SILHOUETTE_INVERTED_TRIANGLE_TITLE"] = "silhouette.silhouette.inverted_triangle";
    I18nMessages["SILHOUETTE_SILHOUETTE_INVERTED_TRIANGLE_TEXT"] = "silhouette.silhouette.inverted_triangle.text";
    I18nMessages["SILHOUETTE_SILHOUETTE_RECTANGLE_TITLE"] = "silhouette.silhouette.rectangle";
    I18nMessages["SILHOUETTE_SILHOUETTE_RECTANGLE_TEXT"] = "silhouette.silhouette.rectangle.text";
    I18nMessages["SILHOUETTE_SILHOUETTE_HOURGLASS_TITLE"] = "silhouette.silhouette.hourglass";
    I18nMessages["SILHOUETTE_SILHOUETTE_HOURGLASS_TEXT"] = "silhouette.silhouette.hourglass.text";
    I18nMessages["SILHOUETTE_SILHOUETTE_DIAMOND_TITLE"] = "silhouette.silhouette.diamond";
    I18nMessages["SILHOUETTE_SILHOUETTE_DIAMOND_TEXT"] = "silhouette.silhouette.diamond.text";
    I18nMessages["SILHOUETTE_SILHOUETTE_GUIDE_MODAL_TITLE"] = "silhouette.silhouette.guide_modal.tittle";
    I18nMessages["SILHOUETTE_GUIDE_MODAL_BUTTON"] = "silhouette.modal.back.cta";
    I18nMessages["SILHOUETTE_SIZES_SUBTITLE"] = "silhouette.sizes.subtitle";
    I18nMessages["SILHOUETTE_SIZES_GUIDE"] = "silhouette.sizes.guide";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_LINK"] = "silhouette.sizes.guide_link";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_MODAL_TITLE"] = "silhouette.sizes.guide_modal.tittle";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_SIZE_HEADER_TITLE"] = "silhouette.sizes.guide.size.header.tittle";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_CHEST_HEADER_TITLE"] = "silhouette.sizes.guide.chest.header.tittle";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_WAIST_HEADER_TITLE"] = "silhouette.sizes.guide.waist.header.tittle";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_HIP_HEADER_TITLE"] = "silhouette.sizes.guide.hip.header.tittle";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_HOWTO_TITLE"] = "silhouette.sizes.guide.howto.tittle";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_CHEST_HOWTO_TITLE"] = "silhouette.sizes.guide.chest.howto.tittle";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_CHEST_HOWTO_TEXT"] = "silhouette.sizes.guide.chest.howto.text";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_WAIST_HOWTO_TITLE"] = "silhouette.sizes.guide.waist.howto.tittle";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_WAIST_HOWTO_TEXT"] = "silhouette.sizes.guide.waist.howto.text";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_HIP_HOWTO_TITLE"] = "silhouette.sizes.guide.hip.howto.tittle";
    I18nMessages["SILHOUETTE_SIZES_GUIDE_HIP_HOWTO_TEXT"] = "silhouette.sizes.guide.hip.howto.text";
    I18nMessages["HOST_SELECT_EMPTY_OPTION"] = "host_select.empty_option";
    I18nMessages["HOST_DATE_PLACEHOLDER"] = "host_date.placeholder";
})(I18nMessages || (I18nMessages = {}));
export { I18nMessages };
