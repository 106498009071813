import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-native";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { Routes } from "../../routing/routes";
import { Screen } from "./Screen";
const RouteScreen = () => {
    /**
     * In order to solve the "unmount" transition, this view will allways be rendered
     * under the path ":slug?", setting as visible or not the Modal.
     *
     * Mantaining a reference to the previous question will allow us to hide the Modal while
     * rendering the previous (invalidated) screen projection.
     */
    const { slug } = useParams();
    const navigate = useNavigate();
    const { basePath } = useStaticInfo();
    const navigateToHome = useCallback(() => navigate(`${basePath}/${Routes.HOME}`), [basePath, navigate]);
    return React.createElement(Screen, { slug: slug, onBack: navigateToHome, onSuccess: navigateToHome });
};
export { RouteScreen };
