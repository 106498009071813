import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space2, space6 } = theme();
const style = StyleSheet.create({
    questionGroup: {
        paddingBottom: space6,
    },
    title: {
        marginBottom: space2,
    },
});
export { style };
