const readFileAsDataURL = async ({ file }) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
        if (reader.result) {
            resolve(reader.result.toString());
        }
        reject();
    };
});
export { readFileAsDataURL };
