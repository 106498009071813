import { useCallback } from "react";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName } from "./tracking";
const useTrackScreenNavigationBack = ({ country, segment, customerId }) => {
    const emitUserEvent = useEmitUserEvent();
    const trackScreenNavigationBack = useCallback(({ slug, group }) => {
        if (!(slug && group)) {
            return;
        }
        emitUserEvent({
            event: TrackingEventName.NAVIGATION_BACK,
            eventCategory: TrackingEventCategory.NAVIGATION,
            section: `${PROJECT}_${slug}`,
            store: country,
            segment,
            userId: customerId,
            group,
        });
    }, [country, customerId, emitUserEvent, segment]);
    return trackScreenNavigationBack;
};
export { useTrackScreenNavigationBack };
