import { isImageUploaded } from "../../../../domain/image/model/imageUploaded";
const httpImagesUpload = ({ multipartHttpPost }) => async ({ aggregateId, customerId, image, domainEvents }) => {
    const imageUploaded = domainEvents.find(isImageUploaded);
    if (!imageUploaded) {
        return;
    }
    await multipartHttpPost({
        endpoint: "/upload-style-profile-image",
        body: {
            id: aggregateId,
            customerId,
            image,
        },
    });
};
export { httpImagesUpload };
