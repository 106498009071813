import { useCallback } from "react";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName } from "./tracking";
const useTrackUploadPhotoOptionsModalClose = ({ country, segment, customerId, }) => {
    const emitUserEvent = useEmitUserEvent();
    const trackUploadPhotoOptionsModalClose = useCallback(() => {
        emitUserEvent({
            event: TrackingEventName.UPLOAD_PHOTO_OPTIONS_MODAL_CLOSE,
            eventCategory: TrackingEventCategory.NAVIGATION,
            section: `${PROJECT}_photos`,
            store: country,
            segment,
            userId: customerId,
        });
    }, [country, emitUserEvent, customerId, segment]);
    return trackUploadPhotoOptionsModalClose;
};
export { useTrackUploadPhotoOptionsModalClose };
