import React, { useMemo } from "react";
import { View } from "react-native";
import { Brand } from "./Brand";
import { Camera } from "./Camera";
import { Color } from "./Color";
import { Measurements } from "./Measurements";
import { style } from "./NextBestActionCardIcon.style";
import { Price } from "./Price";
const IconForAction = {
    ["next_best_action.icon.brands"]: Brand,
    ["next_best_action.icon.photos"]: Camera,
    ["next_best_action.icon.colors_and_patterns"]: Color,
    ["next_best_action.icon.price"]: Price,
    ["next_best_action.icon.measurements"]: Measurements,
};
const NextBestActionCardIcon = ({ icon }) => {
    const Icon = useMemo(() => IconForAction[icon], [icon]);
    return (React.createElement(View, { style: style.icon, testID: icon }, Icon ? React.createElement(Icon, null) : null));
};
export { NextBestActionCardIcon };
