import { isScreenFilled } from "../../../../domain/screen/model/screenFilled";
const httpScreensFill = ({ httpPost }) => async ({ customerId, answers, domainEvents }) => {
    const screenFilled = domainEvents.find(isScreenFilled);
    if (!screenFilled) {
        return;
    }
    await httpPost({
        endpoint: "/fill-style-profile-answers",
        body: {
            customerId,
            answers,
        },
    });
};
export { httpScreensFill };
