import { useCallback } from "react";
import invariant from "tiny-invariant";
import { useCommand } from "@lookiero/messaging-react";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { uploadImage } from "../../../../domain/image/command/uploadImage";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages } from "../../../ui/i18n/i18n";
const useUploadImage = ({ logger }) => {
    const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
    const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });
    const upload = useCallback(async ({ id, image, customerId }) => {
        invariant(customerId, "customerId must be defined in order to upload image");
        try {
            await commandBus(uploadImage({ aggregateId: id, image, customerId }));
        }
        catch (error) {
            logger.captureException(error);
            createNotification({
                level: NotificationLevel.ERROR,
                bodyI18nKey: I18nMessages.UPLOAD_IMAGE_TOAST_GENERIC_ERROR,
            });
        }
    }, [commandBus, createNotification, logger]);
    return [upload, status];
};
export { useUploadImage };
