import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { colorBgBase, space2 } = theme();
const HEADER_HEIGHT = 48;
const style = StyleSheet.create({
    header: {
        alignItems: "center",
        backgroundColor: colorBgBase,
        flexDirection: "row",
        flexGrow: 0,
        flexWrap: "nowrap",
        height: HEADER_HEIGHT,
        justifyContent: "space-between",
        overflow: "visible",
        paddingHorizontal: space2,
        width: "100%",
    },
});
export { style, HEADER_HEIGHT };
