const PROJECT = "style_profile";
var TrackingPage;
(function (TrackingPage) {
    TrackingPage["NOT_FOUND"] = "not_found";
    TrackingPage["STYLE"] = "style";
})(TrackingPage || (TrackingPage = {}));
var TrackingEventName;
(function (TrackingEventName) {
    TrackingEventName["PAGEVIEW"] = "pageview";
    TrackingEventName["NAVIGATION_BACK"] = "navigationBack";
    TrackingEventName["FILL_STYLE_PROFILE"] = "styleProfileFilled";
    TrackingEventName["UPLOAD_PHOTO_UPLOAD_ICON_SELECTED"] = "uploadPhotoUploadIconSelected";
    TrackingEventName["UPLOAD_PHOTO_OPTIONS_MODAL_VIEW"] = "uploadPhotoOptionsModalView";
    TrackingEventName["UPLOAD_PHOTO_TAKE_PHOTO_OPTION_SELECTED"] = "uploadPhotoTakePhotoOptionSelected";
    TrackingEventName["UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_SELECTED"] = "uploadPhotoChooseLibraryOptionSelected";
    TrackingEventName["UPLOAD_PHOTO_ERROR"] = "uploadPhotoError";
    TrackingEventName["IMAGE_UPLOADED"] = "imageUploaded";
    TrackingEventName["UPLOAD_PHOTO_OPTIONS_MODAL_CLOSE"] = "uploadPhotoOptionsModalClose";
    TrackingEventName["UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_CANCELED"] = "uploadPhotoChooseLibraryOptionCanceled";
    TrackingEventName["UPLOAD_PHOTO_TAKE_PHOTO_OPTION_CANCELED"] = "uploadPhotoTakePhotoOptionCanceled";
    TrackingEventName["QUESTION_ERROR"] = "questionError";
    TrackingEventName["NEXT_BEST_ACTION_CLICK"] = "nextBestActionClick";
})(TrackingEventName || (TrackingEventName = {}));
export { PROJECT, TrackingPage, TrackingEventName };
