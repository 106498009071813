import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space1, space2, space4, colorIconSuccess } = theme();
const style = StyleSheet.create({
    container: {
        paddingVertical: space4,
    },
    progress: {
        color: colorIconSuccess,
    },
    title: {
        marginRight: space1,
    },
    titleContainer: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: space4,
        paddingVertical: space2,
    },
});
export { style };
