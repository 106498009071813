import { useCallback } from "react";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName } from "./tracking";
const useTrackUploadPhotoTakePhotoOptionCanceled = ({ country, segment, customerId, }) => {
    const emitUserEvent = useEmitUserEvent();
    const trackUploadPhotoTakePhotoOptionCanceled = useCallback(() => {
        emitUserEvent({
            event: TrackingEventName.UPLOAD_PHOTO_TAKE_PHOTO_OPTION_CANCELED,
            eventCategory: TrackingEventCategory.NAVIGATION,
            section: `${PROJECT}_photos`,
            store: country,
            segment,
            userId: customerId,
        });
    }, [country, customerId, emitUserEvent, segment]);
    return trackUploadPhotoTakePhotoOptionCanceled;
};
export { useTrackUploadPhotoTakePhotoOptionCanceled };
