const httpImageByIdView = ({ httpPost }) => async ({ id, signal }) => await httpPost({
    endpoint: "/view-style-profile-image-by-id",
    body: {
        id,
    },
    signal,
    result: {
        error: null,
        success: (response) => response.result,
    },
});
export { httpImageByIdView };
