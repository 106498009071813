import { useEffect, useState } from "react";
import { readFileAsDataURL } from "../../file/readFileAsDataURL";
import { useStaticInfo } from "./useStaticInfo";
const useFecthImage = ({ endpoint, enabled = true }) => {
    const { apiUrl, getAuthToken } = useStaticInfo();
    const [image, setImage] = useState();
    const [status, setStatus] = useState("idle");
    useEffect(() => {
        const fetchImage = async () => {
            if (!enabled) {
                return;
            }
            setStatus("loading");
            try {
                const response = await fetch(`${apiUrl()}${endpoint}`, {
                    method: "GET",
                    headers: {
                        Authorization: getAuthToken ? `Bearer ${await getAuthToken()}` : "",
                    },
                });
                const data = await response.blob();
                const image = await readFileAsDataURL({ file: data });
                setImage(image);
                setStatus("success");
            }
            catch (error) {
                setStatus("error");
            }
        };
        fetchImage();
    }, [apiUrl, enabled, endpoint, getAuthToken]);
    return [image, status];
};
export { useFecthImage };
