/* eslint-disable react/prop-types */
import React from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useIntl } from "@lookiero/i18n-react";
import { style } from "./HostDefaultQuestionItem.style";
const HostDefaultQuestionItem = ({ question, children }) => {
    const { formatMessage } = useIntl();
    const title = question.translationKey ? formatMessage({ id: question.translationKey }) : null;
    return (React.createElement(View, { style: Boolean(title) && style.questionGroup, testID: "host-default-question-item" },
        title && (React.createElement(Text, { level: 2, style: style.title, action: true }, title)),
        children));
};
export { HostDefaultQuestionItem };
