import invariant from "tiny-invariant";
import { viewImageById } from "../../../../projection/image/viewImageById";
import { httpImagesUpload } from "./httpImagesUpload";
const toDomain = (image) => {
    invariant(image, "No image found!");
    return {
        styleProfileId: image.styleProfileId,
        customerId: undefined,
        aggregateId: image.id,
        domainEvents: [],
    };
};
const getImage = ({ queryBus }) => async (aggregateId) => toDomain(await queryBus(viewImageById({ id: aggregateId })));
const saveImage = ({ multipartHttpPost }) => async (aggregateRoot) => await httpImagesUpload({ multipartHttpPost })(aggregateRoot);
export { getImage, saveImage };
