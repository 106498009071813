import React from "react";
import { Svg, Path } from "react-native-svg";
import { theme } from "@lookiero/sty-psp-ui";
const { colorIcon } = theme();
const Price = () => (React.createElement(Svg, { fill: "none", viewBox: "0 0 40 40" },
    React.createElement(Path, { d: "M15.8464 38C18.7841 38 21.6016 36.1036 23.6789 32.728C25.7562 29.3523 26.9232 24.774 26.9232 20.0001C26.9232 15.2263 25.7562 10.6479 23.6789 7.27231C21.6016 3.89668 18.7841 2.00027 15.8464 2.00027C12.9086 2.00027 10.0912 3.89668 8.01386 7.27231C5.93655 10.6479 4.76953 15.2263 4.76953 20.0001C4.76953 24.774 5.93655 29.3523 8.01386 32.728C10.0912 36.1036 12.9086 38 15.8464 38Z", stroke: colorIcon, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }),
    React.createElement(Path, { d: "M26.9238 20.002H35.2315", stroke: colorIcon, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }),
    React.createElement(Path, { d: "M25.1924 29.6934H33.4568", stroke: colorIcon, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }),
    React.createElement(Path, { d: "M25.1924 10.3086H33.4568", stroke: colorIcon, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" }),
    React.createElement(Path, { d: "M26.9225 19.9999C26.9225 10.0588 21.9633 2 15.8457 2H24.1533C30.2708 2 35.2302 10.0588 35.2302 19.9999C35.2302 29.9411 30.2708 37.9997 24.1533 37.9997H15.8457C21.9633 37.9997 26.9225 29.9411 26.9225 19.9999Z", stroke: colorIcon, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2" })));
export { Price };
