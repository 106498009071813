import { useLayoutEffect } from "react";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName } from "./tracking";
const useTrackScreenPageView = ({ slug, country, segment, customerId }) => {
    const emitUserEvent = useEmitUserEvent();
    useLayoutEffect(() => {
        if (!slug) {
            return;
        }
        emitUserEvent({
            event: TrackingEventName.PAGEVIEW,
            eventCategory: TrackingEventCategory.NAVIGATION,
            section: `${PROJECT}_${slug}`,
            store: country,
            segment,
            userId: customerId,
        });
    }, [country, customerId, emitUserEvent, segment, slug]);
};
export { useTrackScreenPageView };
