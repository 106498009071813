import { bootstrap as messagingBootstrap } from "@lookiero/messaging-react";
import { bootstrapWithBuilder as bootstrapNotifications, inMemoryStorageNotifications, } from "@lookiero/sty-psp-notifications";
import { bootstrapWithBuilder as bootstrapQuizStyleProfileCommonUi } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { UPLOAD_IMAGE } from "../../domain/image/command/uploadImage";
import { uploadImageHandler } from "../../domain/image/model/image";
import { FILL_SCREEN } from "../../domain/screen/command/fillScreen";
import { fillScreenHandler } from "../../domain/screen/model/screen";
import { VIEW_IMAGE_BY_ID, viewImageByIdHandler } from "../../projection/image/viewImageById";
import { VIEW_STYLE_PROFILE_NEXT_BEST_ACTION_DEFINITION_BY_CUSTOMER_ID, viewStyleProfileNextBestActionDefinitionByCustomerIdHandler, } from "../../projection/nextBestActionDefinition/viewStyleProfileNextBestActionDefinitionByCustomerId";
import { VIEW_SCREEN_BY_SLUG_AND_CUSTOMER_ID, viewScreenBySlugAndCustomerIdHandler, } from "../../projection/screen/viewScreenBySlugAndCustomerId";
import { VIEW_STYLE_PROFILE_ANSWERS_BY_CUSTOMER_ID, viewStyleProfileAnswersByCustomerIdHandler, } from "../../projection/styleProfileAnswers/viewStyleProfileAnswersByCustomerId";
import { VIEW_STYLE_PROFILE_DEFINITION_BY_CUSTOMER_ID, viewStyleProfileDefinitionByCustomerIdHandler, } from "../../projection/styleProfileDefinition/viewStyleProfileDefinitionByCustomerId";
const MESSAGING_CONTEXT_ID = "StyleProfile";
const baseBootstrap = ({ styleProfileDefinitionByCustomerIdView, screenBySlugAndCustomerIdView, styleProfileAnswersByCustomerIdView, styleProfileNextBestActionDefinitionByCustomerIdView, screensDependencies, getScreen, saveScreen, imageByIdView, imagesDependencies, getImage, saveImage, }) => {
    let messaging = messagingBootstrap({ id: MESSAGING_CONTEXT_ID })
        .query(VIEW_STYLE_PROFILE_DEFINITION_BY_CUSTOMER_ID, viewStyleProfileDefinitionByCustomerIdHandler, {
        view: styleProfileDefinitionByCustomerIdView,
    })
        .query(VIEW_SCREEN_BY_SLUG_AND_CUSTOMER_ID, viewScreenBySlugAndCustomerIdHandler, {
        view: screenBySlugAndCustomerIdView,
    })
        .query(VIEW_STYLE_PROFILE_ANSWERS_BY_CUSTOMER_ID, viewStyleProfileAnswersByCustomerIdHandler, {
        view: styleProfileAnswersByCustomerIdView,
    })
        .command(FILL_SCREEN, fillScreenHandler)(getScreen, saveScreen, ...screensDependencies)
        .query(VIEW_IMAGE_BY_ID, viewImageByIdHandler, {
        view: imageByIdView,
    })
        .command(UPLOAD_IMAGE, uploadImageHandler)(getImage, saveImage, ...imagesDependencies)
        .query(VIEW_STYLE_PROFILE_NEXT_BEST_ACTION_DEFINITION_BY_CUSTOMER_ID, viewStyleProfileNextBestActionDefinitionByCustomerIdHandler, {
        view: styleProfileNextBestActionDefinitionByCustomerIdView,
    });
    messaging = bootstrapNotifications({ messaging, storage: inMemoryStorageNotifications() });
    messaging = bootstrapQuizStyleProfileCommonUi({ messaging });
    return messaging.build();
};
export { MESSAGING_CONTEXT_ID, baseBootstrap };
