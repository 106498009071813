import { useQuery } from "@lookiero/messaging-react";
import { isScreenFilled } from "../../../../domain/screen/model/screenFilled";
import { viewStyleProfileAnswersByCustomerId } from "../../../../projection/styleProfileAnswers/viewStyleProfileAnswersByCustomerId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
const shouldInvalidate = (event) => isScreenFilled(event);
const useViewStyleProfileAnswersByCustomerId = ({ customerId }) => useQuery({
    query: viewStyleProfileAnswersByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
    },
});
export { useViewStyleProfileAnswersByCustomerId };
