/* eslint-disable react/prop-types */
import { PortalProvider } from "@gorhom/portal";
import React from "react";
import { Platform } from "react-native";
import { sentryLogger, sentryLoggerHOC } from "@lookiero/sty-psp-logging";
import { Notifications } from "@lookiero/sty-psp-notifications";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { NextBestAction, } from "../../views/nextBestAction/NextBestAction";
import { nextBestActionProviders } from "./NextBestActionProviders";
const nextBestActionRoot = ({ Messaging, I18n, kameleoon: kameleoonConfig, sentry, development, apiUrl, getAuthToken, }) => {
    const logger = sentryLogger(sentry);
    const kameleoon = kameleoonConfig();
    const onI18nError = (error) => (development ? void 0 : logger.captureException(error));
    const Providers = nextBestActionProviders({ apiUrl, getAuthToken, I18n, kameleoon, onI18nError });
    const NextBestActionRootHost = ({ customer, locale, iOSInAppBrowserVideoAd, iOSInAppBrowserVideoAdBannerHeight, children, }) => (React.createElement(Messaging, { includeReactQueryDevTools: Platform.OS === "web" },
        React.createElement(Providers, { customer: customer, locale: locale },
            React.createElement(PortalProvider, { rootHostName: "StyleProfile" },
                React.createElement(Notifications, { contextId: MESSAGING_CONTEXT_ID, style: { toast: iOSInAppBrowserVideoAd && { top: iOSInAppBrowserVideoAdBannerHeight } } }),
                children))));
    const NextBestActionRoot = ({ customer, locale, onCompleted, onReady, onNavigateToStyleProfile, }) => (React.createElement(Providers, { customer: customer, locale: locale },
        React.createElement(NextBestAction, { onCompleted: onCompleted, onNavigateToStyleProfile: onNavigateToStyleProfile, onReady: onReady })));
    const hoc = sentryLoggerHOC({ logger });
    /**
     * It's important to not call sentryLoggerHOC each time this function gets called.
     * That's why its called outside this method.
     */
    return ({ customerId, rootClient }) => ({
        NextBestActionRootHost: hoc({ customerId, rootClient })(NextBestActionRootHost),
        NextBestActionRoot: hoc({ customerId, rootClient })(NextBestActionRoot),
    });
};
export { nextBestActionRoot };
