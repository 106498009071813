import { useLayoutEffect } from "react";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName } from "./tracking";
const useTrackPageView = ({ page, country, segment, customerId }) => {
    const emitUserEvent = useEmitUserEvent();
    useLayoutEffect(() => {
        emitUserEvent({
            event: TrackingEventName.PAGEVIEW,
            eventCategory: TrackingEventCategory.NAVIGATION,
            section: `${PROJECT}_${page}`,
            store: country,
            segment,
            userId: customerId,
        });
    }, [country, customerId, emitUserEvent, page, segment]);
};
export { useTrackPageView };
