import { useCallback } from "react";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName } from "./tracking";
const useTrackQuestionError = ({ slug, country, segment, customerId, group }) => {
    const emitUserEvent = useEmitUserEvent();
    const trackQuestionError = useCallback(({ validation }) => {
        if (!validation) {
            return;
        }
        validation.forEach((v) => {
            const errorType = v.value ? "format" : v.mandatory && v.ctaTriggered ? "mandatory" : undefined;
            if (!errorType) {
                return;
            }
            emitUserEvent({
                event: TrackingEventName.QUESTION_ERROR,
                eventCategory: TrackingEventCategory.NAVIGATION,
                section: `${PROJECT}_${slug}`,
                store: country,
                segment,
                userId: customerId,
                group,
                errorType,
            });
        });
    }, [country, customerId, emitUserEvent, group, segment, slug]);
    return trackQuestionError;
};
export { useTrackQuestionError };
