import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { colorBgPrimaryLight, colorTextMedium, borderRadius3, space1, space4 } = theme();
const style = StyleSheet.create({
    card: {
        backgroundColor: colorBgPrimaryLight,
        borderRadius: borderRadius3,
        flex: 1,
        justifyContent: "space-between",
        padding: space4,
        width: "100%",
    },
    content: {
        marginBottom: space4,
    },
    cta: {
        alignSelf: "flex-start",
    },
    description: {
        color: colorTextMedium,
        marginBottom: space1,
    },
    iconContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: space4,
    },
    title: {
        marginBottom: space1,
    },
});
export { style };
