import { mapAnswersToInternationalSystem, viewValidation, } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { viewScreenBySlugAndCustomerId } from "../../../projection/screen/viewScreenBySlugAndCustomerId";
import { screenFilled } from "./screenFilled";
const fillScreenHandler = ({ queryBus }) => async ({ aggregateRoot, command }) => {
    const { aggregateId, customerId, answers, slug } = command;
    const screen = await queryBus(viewScreenBySlugAndCustomerId({ slug, customerId }));
    if (!screen) {
        throw new Error("Can not fill the screen. Screen not found");
    }
    const validation = await queryBus(viewValidation({ answers, questions: [screen] }));
    if (validation?.length > 0) {
        throw new Error("Can not fill the screen. There are validation errors.");
    }
    return {
        ...aggregateRoot,
        customerId,
        answers: mapAnswersToInternationalSystem({ answers, questions: [screen] }),
        domainEvents: [screenFilled({ aggregateId })],
    };
};
export { fillScreenHandler };
