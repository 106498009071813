import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space4, space6 } = theme();
const style = StyleSheet.create({
    button: {
        flex: 1,
    },
    modal: {
        paddingHorizontal: space6,
    },
    modalGap: {
        marginTop: space6,
    },
    takePhotoButton: {
        marginBottom: space4,
    },
});
export { style };
