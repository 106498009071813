import React, { useMemo, useRef } from "react";
import { View } from "react-native";
import Animated, { FadeIn, FadeOut, LinearTransition } from "react-native-reanimated";
import { NextBestActionCard } from "../../nextBestActionCard/NextBestActionCard";
import { style } from "./NextBestActionGrid.style";
const GRID_MAX_ACTIONS = 4;
const layoutAnimations = {
    entering: FadeIn.delay(600).duration(300),
    exiting: FadeOut.delay(300).duration(300),
    layout: LinearTransition.delay(450).duration(300),
};
// eslint-disable-next-line react/prop-types
const NextBestActionGrid = ({ actions = [], onActionTriggered }) => {
    const gridActions = useMemo(() => actions.slice(0, GRID_MAX_ACTIONS), [actions]);
    const initialActions = useRef(actions);
    const renderWithLayoutAnimations = initialActions.current !== actions;
    return (React.createElement(View, { style: style.gridContainer }, gridActions.map((action) => (React.createElement(Animated.View, { key: action.id, ...(renderWithLayoutAnimations ? layoutAnimations : {}), style: style.card },
        React.createElement(NextBestActionCard, { action: action, status: action.status, onActionTriggered: onActionTriggered }))))));
};
export { NextBestActionGrid };
