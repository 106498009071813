import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space1 } = theme();
const style = StyleSheet.create({
    card: {
        paddingHorizontal: space1,
        paddingVertical: space1,
        width: "50%",
    },
    gridContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginHorizontal: -space1,
    },
});
export { style };
