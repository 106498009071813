import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from "react-native-reanimated";
import { Button, COLOR, Icon, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { NextBestActionCardIcon } from "../nextBestActionCardIcon/NextBestActionCardIcon";
import { style } from "./NextBestActionCard.style";
const DEFAULT_STATUS_TIMEOUT = 2000;
const ICON_ANIMATION_MIN_SCALE = 0.8;
var NextBestActionCardStatus;
(function (NextBestActionCardStatus) {
    NextBestActionCardStatus["IDLE"] = "IDLE";
    NextBestActionCardStatus["SUCCESS"] = "SUCCESS";
})(NextBestActionCardStatus || (NextBestActionCardStatus = {}));
const NextBestActionCard = ({ action, status = NextBestActionCardStatus.IDLE, onActionTriggered, }) => {
    const title = useI18nMessage({ id: action.title });
    const description = useI18nMessage({ id: action.description });
    const cta = useI18nMessage({ id: action.cta });
    const handleOnCtaPress = useCallback(() => onActionTriggered({ action }), [action, onActionTriggered]);
    const [currentStatus, setCurrentStatus] = useState(status);
    useEffect(() => {
        setCurrentStatus(status);
        const initTimeout = () => setTimeout(() => setCurrentStatus(NextBestActionCardStatus.IDLE), DEFAULT_STATUS_TIMEOUT);
        if (status === NextBestActionCardStatus.IDLE) {
            return;
        }
        const timeout = initTimeout();
        return () => clearTimeout(timeout);
    }, [status]);
    const successIconAnimationValue = useSharedValue(currentStatus === NextBestActionCardStatus.IDLE ? 0 : 1);
    successIconAnimationValue.value = withSpring(currentStatus === NextBestActionCardStatus.IDLE ? 0 : 1);
    const successIconAnimatedStyles = useAnimatedStyle(() => ({
        transform: [
            {
                scale: ICON_ANIMATION_MIN_SCALE + (1 - ICON_ANIMATION_MIN_SCALE) * successIconAnimationValue.value,
            },
        ],
        opacity: successIconAnimationValue.value,
    }), [successIconAnimationValue]);
    return (React.createElement(View, { style: style.card, testID: action.id },
        React.createElement(View, { style: style.content },
            React.createElement(View, { style: style.iconContainer },
                React.createElement(NextBestActionCardIcon, { icon: action.icon }),
                React.createElement(Animated.View, { style: successIconAnimatedStyles, testID: "next-best-action-card-success-icon" },
                    React.createElement(Icon, { color: COLOR.ICON_SUCCESS, name: "tick_fill" }))),
            React.createElement(Text, { level: 3, style: style.title, action: true }, title),
            React.createElement(Text, { level: 2, style: style.description, detail: true }, description)),
        React.createElement(Button, { disabled: currentStatus === NextBestActionCardStatus.SUCCESS, style: style.cta, testID: "next-best-action-card-cta", wide: false, small: true, onPress: handleOnCtaPress }, cta)));
};
export { NextBestActionCard, NextBestActionCardStatus };
