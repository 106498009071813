/* eslint-disable react/prop-types */
import React, { useCallback, useState } from "react";
import { Platform, Pressable } from "react-native";
import { launchCamera, launchImageLibrary } from "react-native-image-picker";
import Animated, { useAnimatedStyle, withTiming } from "react-native-reanimated";
import { COLOR } from "@lookiero/aurora";
import { useLogger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { useAnswerForId, useAnswersValidation, useUploadImage, } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { AspectRatioView, Icon } from "@lookiero/sty-psp-ui";
import { useUploadImage as useUploadImageCommand } from "../../../../../../../infrastructure/domain/image/react/useUploadImage";
import { MESSAGING_CONTEXT_ID } from "../../../../../../delivery/baseBootstrap";
import { useTrackImageUploaded } from "../../../../../../tracking/useTrackImageUploaded";
import { useTrackUploadPhotoChooseLibraryOptionCanceled } from "../../../../../../tracking/useTrackUploadPhotoChooseLibraryOptionCanceled";
import { useTrackUploadPhotoChooseLibraryOptionSelected } from "../../../../../../tracking/useTrackUploadPhotoChooseLibraryOptionSelected";
import { useTrackUploadPhotoError } from "../../../../../../tracking/useTrackUploadPhotoError";
import { useTrackUploadPhotoOptionsModalClose } from "../../../../../../tracking/useTrackUploadPhotoOptionsModalClose";
import { useTrackUploadPhotoOptionsModalView } from "../../../../../../tracking/useTrackUploadPhotoOptionsModalView";
import { useTrackUploadPhotoTakePhotoOptionCanceled } from "../../../../../../tracking/useTrackUploadPhotoTakePhotoOptionCanceled";
import { useTrackUploadPhotoTakePhotoOptionSelected } from "../../../../../../tracking/useTrackUploadPhotoTakePhotoOptionSelected";
import { useTrackUploadPhotoUploadIconSelected } from "../../../../../../tracking/useTrackUploadPhotoUploadIconSelected";
import { useFecthImage } from "../../../../../hooks/useFetchImage";
import { useStaticInfo } from "../../../../../hooks/useStaticInfo";
import { I18nMessages } from "../../../../../i18n/i18n";
import { style } from "./HostImageUploadQuestionItem.style";
import { ImagePickerModal } from "./components/imagePickerModal/ImagePickerModal";
import { requestCameraPermissions, requestMediaLibraryPermissions } from "./permissions";
const IMAGE_LIBRARY_OPTIONS = {
    mediaType: "photo",
    selectionLimit: 1,
    quality: 1,
};
const CAMERA_OPTIONS = {
    mediaType: "photo",
    saveToPhotos: true,
    quality: 1,
};
const IMAGE_ANIMATIONS_OPTIONS = {
    duration: 500,
};
const HostImageUploadQuestionItem = ({ question }) => {
    const { customer: { country, customerId, segment }, } = useStaticInfo();
    const logger = useLogger();
    const [imageLoading, setImageLoading] = useState(false);
    const trackUploadPhotoUploadIconSelected = useTrackUploadPhotoUploadIconSelected({
        country,
        customerId,
        segment,
    });
    const trackUploadPhotoOptionsModalView = useTrackUploadPhotoOptionsModalView({
        country,
        customerId,
        segment,
    });
    const trackUploadPhotoTakePhotoOptionSelected = useTrackUploadPhotoTakePhotoOptionSelected({
        country,
        customerId,
        segment,
    });
    const trackUploadPhotoChooseLibraryOptionSelected = useTrackUploadPhotoChooseLibraryOptionSelected({
        country,
        customerId,
        segment,
    });
    const trackUploadPhotoError = useTrackUploadPhotoError({
        country,
        customerId,
        segment,
    });
    const trackImageUploaded = useTrackImageUploaded({
        country,
        customerId,
        segment,
    });
    const trackUploadPhotoOptionsModalClose = useTrackUploadPhotoOptionsModalClose({
        country,
        customerId,
        segment,
    });
    const trackUploadPhotoChooseLibraryOptionCanceled = useTrackUploadPhotoChooseLibraryOptionCanceled({
        country,
        customerId,
        segment,
    });
    const trackUploadPhotoTakePhotoOptionCanceled = useTrackUploadPhotoTakePhotoOptionCanceled({
        country,
        customerId,
        segment,
    });
    const [imagePickerModalVisible, setImagePickerModalVisible] = useState(false);
    const handleOnImagePickerModalClose = useCallback(() => setImagePickerModalVisible(false), []);
    const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });
    const handleOnError = useCallback((error) => {
        trackUploadPhotoError();
        createNotification({
            level: NotificationLevel.ERROR,
            bodyI18nKey: error || I18nMessages.UPLOAD_IMAGE_TOAST_GENERIC_ERROR,
        });
    }, [createNotification, trackUploadPhotoError]);
    const [upload, uploadStatus] = useUploadImageCommand({
        logger,
    });
    const uploadImage = useUploadImage({
        onStart: handleOnImagePickerModalClose,
        onSuccess: async ({ id, image }) => {
            await upload({
                id,
                image,
                customerId,
            });
            trackImageUploaded();
            onChange({
                questionId: question.id,
                answer: id,
            });
            validateMaxAnswers();
        },
        onError: handleOnError,
    });
    const { answer, onChange } = useAnswerForId({ id: question.id });
    const selectedAnswer = answer && answer[0];
    const [image, imageStatus] = useFecthImage({
        endpoint: `/view-style-profile-image-by-id/${selectedAnswer}`,
        enabled: Boolean(selectedAnswer),
    });
    const { validateMaxAnswers } = useAnswersValidation();
    const handleOnTakePhoto = useCallback(async () => {
        trackUploadPhotoTakePhotoOptionSelected();
        const granted = await requestCameraPermissions();
        if (granted) {
            try {
                const response = await launchCamera(CAMERA_OPTIONS);
                if (response.errorCode) {
                    handleOnError();
                }
                if (response.didCancel) {
                    trackUploadPhotoTakePhotoOptionCanceled();
                    return;
                }
                const image = response.assets?.[0];
                if (image && image.uri) {
                    await uploadImage({ uri: image.uri });
                }
            }
            catch (error) {
                handleOnError();
            }
        }
    }, [handleOnError, trackUploadPhotoTakePhotoOptionCanceled, trackUploadPhotoTakePhotoOptionSelected, uploadImage]);
    const handleOnChooseFromLibrary = useCallback(async () => {
        trackUploadPhotoChooseLibraryOptionSelected();
        const granted = await requestMediaLibraryPermissions();
        if (granted) {
            try {
                const response = await launchImageLibrary(IMAGE_LIBRARY_OPTIONS);
                if (response.errorCode) {
                    handleOnError();
                }
                if (response.didCancel) {
                    trackUploadPhotoChooseLibraryOptionCanceled();
                    return;
                }
                const image = response.assets?.[0];
                if (image && image.uri) {
                    await uploadImage({ uri: image.uri });
                }
            }
            catch (error) {
                handleOnError();
            }
        }
    }, [
        handleOnError,
        trackUploadPhotoChooseLibraryOptionCanceled,
        trackUploadPhotoChooseLibraryOptionSelected,
        uploadImage,
    ]);
    const handleOnDismiss = useCallback(() => {
        trackUploadPhotoOptionsModalClose();
        handleOnImagePickerModalClose();
    }, [handleOnImagePickerModalClose, trackUploadPhotoOptionsModalClose]);
    const handleOnPress = useCallback(async () => {
        trackUploadPhotoUploadIconSelected();
        if (Platform.OS !== "web") {
            setImagePickerModalVisible(true);
            trackUploadPhotoOptionsModalView();
        }
        else {
            await handleOnChooseFromLibrary();
        }
    }, [handleOnChooseFromLibrary, trackUploadPhotoOptionsModalView, trackUploadPhotoUploadIconSelected]);
    const animatedStyle = useAnimatedStyle(() => ({
        opacity: withTiming(imageLoading ? 0 : 1, IMAGE_ANIMATIONS_OPTIONS),
    }), [imageLoading]);
    const handleOnLoadStart = useCallback(() => setImageLoading(true), []);
    const handleOnLoadEnd = useCallback(() => setImageLoading(false), []);
    const loading = uploadStatus === "loading" || imageStatus === "loading" || imageLoading;
    return (React.createElement(React.Fragment, null,
        React.createElement(ImagePickerModal, { disabled: loading, visible: imagePickerModalVisible, onChooseFromLibrary: handleOnChooseFromLibrary, onDismiss: handleOnDismiss, onTakePhoto: handleOnTakePhoto }),
        React.createElement(AspectRatioView, { aspectRatio: 5 / 4 },
            React.createElement(Pressable, { accessibilityLabel: "host-image-upload-question-item", disabled: loading, style: style.container, testID: "host-image-upload-question-item", accessible: true, onPress: handleOnPress }, selectedAnswer && uploadStatus !== "loading" && imageStatus !== "loading" ? (React.createElement(Animated.Image, { resizeMode: "cover", source: { uri: image }, testID: question.id, style: [
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    style.image,
                    animatedStyle,
                ], onLoadEnd: handleOnLoadEnd, onLoadStart: handleOnLoadStart })) : (React.createElement(Icon, { color: COLOR.GRAYSCALE_L, name: loading ? "hourglass" : "expand" }))))));
};
export { HostImageUploadQuestionItem };
