import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space05, space1, space2, space6, borderRadiusFull, colorTextMedium, colorIconError } = theme();
const style = StyleSheet.create({
    answer: {
        color: colorTextMedium,
    },
    iconContainer: {
        alignItems: "center",
        height: space6,
        justifyContent: "center",
        width: space6,
    },
    notification: {
        backgroundColor: colorIconError,
        borderRadius: borderRadiusFull,
        height: space1 + space05,
        position: "absolute",
        right: 0,
        top: 0,
        width: space1 + space05,
    },
    question: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingVertical: space2,
    },
    questionAndAnswer: {
        flex: 1,
        paddingRight: space2,
    },
});
export { style };
