import { imageUploaded } from "./imageUploaded";
2;
const uploadImageHandler = () => async ({ aggregateRoot, command }) => {
    const { aggregateId, customerId, image } = command;
    return {
        ...aggregateRoot,
        customerId,
        image,
        domainEvents: [imageUploaded({ aggregateId })],
    };
};
export { uploadImageHandler };
