import React from "react";
import { Svg, Path, Circle } from "react-native-svg";
import { theme } from "@lookiero/sty-psp-ui";
const { colorIcon, colorBgPrimaryLight } = theme();
const Color = () => (React.createElement(Svg, { fill: "none", viewBox: "0 0 40 40" },
    React.createElement(Path, { d: "M37 26L37 36C37 36.5523 36.5523 37 36 37L9 37C5.68629 37 3 34.3137 3 31C3 27.6863 5.68629 25 9 25L36 25C36.5523 25 37 25.4477 37 26Z", fill: colorBgPrimaryLight, stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Circle, { cx: "9", cy: "31", fill: colorBgPrimaryLight, r: "2", stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Path, { d: "M19 25L19 37", stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Path, { d: "M28 25L28 37", stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Path, { d: "M24.7071 8.12132L31.7782 15.1924C32.1687 15.5829 32.1687 16.2161 31.7782 16.6066L13.7974 34.5874C11.4542 36.9306 7.65521 36.9306 5.31207 34.5874C2.96892 32.2443 2.96892 28.4453 5.31207 26.1021L23.2929 8.12132C23.6834 7.7308 24.3166 7.7308 24.7071 8.12132Z", fill: colorBgPrimaryLight, stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Path, { d: "M11.272 20.1426L19.7573 28.6279", stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Path, { d: "M17.636 13.7783L26.1213 22.2636", stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Path, { d: "M4 3H14C14.5523 3 15 3.44772 15 4V31C15 34.3137 12.3137 37 9 37C5.68629 37 3 34.3137 3 31V4C3 3.44772 3.44772 3 4 3Z", fill: colorBgPrimaryLight, stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Circle, { cx: "9", cy: "31", fill: colorBgPrimaryLight, r: "2", stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Path, { d: "M3 21H15", stroke: colorIcon, strokeWidth: "2" }),
    React.createElement(Path, { d: "M3 12H15", stroke: colorIcon, strokeWidth: "2" })));
export { Color };
