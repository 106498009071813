import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space2 } = theme();
const CARD_WIDTH = 250;
const style = StyleSheet.create({
    card: {
        flex: 1,
        width: CARD_WIDTH,
    },
    carouselContainer: {
        overflow: "visible",
        width: CARD_WIDTH + space2,
    },
});
export { style };
