import React from "react";
import Animated, { useAnimatedProps, useAnimatedStyle, useSharedValue, withDelay, withSpring, withTiming, } from "react-native-reanimated";
import { Svg, Path, Mask, G, Rect } from "react-native-svg";
import { theme } from "@lookiero/sty-psp-ui";
import { style } from "./Tick.style";
const AnimatedRect = Animated.createAnimatedComponent(Rect);
const { colorIconSuccess, colorBgBase } = theme();
const TICK_MASK_WIDTH = 21;
const MIN_SCALE = 0.5;
const Tick = () => {
    const rectWidth = useSharedValue(0);
    rectWidth.value = withDelay(450, withTiming(TICK_MASK_WIDTH, { duration: 300 }));
    const animatedRectProps = useAnimatedProps(() => ({ width: rectWidth.value }), [rectWidth]);
    const animation = useSharedValue(0);
    animation.value = withSpring(1);
    const animatedStyles = useAnimatedStyle(() => ({
        opacity: animation.value,
        transform: [{ scale: MIN_SCALE + (1 - MIN_SCALE) * animation.value }],
    }), [animation]);
    return (React.createElement(Animated.View, { style: [style.icon, animatedStyles], testID: "next-best-action-success-tick" },
        React.createElement(Svg, { fill: "none", viewBox: "0 0 61 60" },
            React.createElement(Path, { d: "M8.5 30C8.5 17.8497 18.3497 8 30.5 8C42.6503 8 52.5 17.8497 52.5 30C52.5 42.1503 42.6503 52 30.5 52C18.3497 52 8.5 42.1503 8.5 30Z", fill: colorIconSuccess }),
            React.createElement(Mask, { height: "16", id: "mask0_7374_12872", maskUnits: "userSpaceOnUse", width: "22", x: "20", y: "22" },
                React.createElement(Path, { d: "M38.1581 22.5858C38.9226 21.8047 40.1621 21.8047 40.9266 22.5858C41.6911 23.3668 41.6911 24.6332 40.9266 25.4142L29.1809 37.4142C28.4164 38.1953 27.1769 38.1953 26.4124 37.4142L21.0734 31.9597C20.3089 31.1786 20.3089 29.9123 21.0734 29.1312C21.8379 28.3502 23.0774 28.3502 23.8419 29.1312L27.7966 33.1716L38.1581 22.5858Z", fill: colorBgBase })),
            React.createElement(G, { mask: "url(#mask0_7374_12872)" },
                React.createElement(AnimatedRect, { animatedProps: animatedRectProps, fill: "white", height: "16", x: "20.5", y: "22" })))));
};
export { Tick };
