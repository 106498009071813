import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadius3, colorBgInteractiveActive } = theme();
const style = StyleSheet.create({
    container: {
        alignItems: "center",
        backgroundColor: colorBgInteractiveActive,
        borderRadius: borderRadius3,
        flex: 1,
        justifyContent: "center",
        overflow: "hidden",
    },
    image: {
        height: "100%",
        width: "100%",
    },
});
export { style };
